<template>
	<v-dialog
		v-model="closeState"
		max-width="500px"
		persistent
	>
		<v-card flat>
			<v-card-title>{{Mode.toUpperCase()}} TÀI KHOẢN: {{account}}</v-card-title>
			<v-card-text>
				<v-currency-field
					v-model="Balance"
					class="mx-2"
					label="Số tiền"
				/>
				<v-textarea
					v-model="Notes"
					class="mx-2"
					label="Ghi chú (Khách hàng)"
				/>
				<v-textarea
					v-model="Logs"
					class="mx-2"
					label="Ghi chú (Nội bộ)"
				/>
			</v-card-text>
			<v-card-actions>
				<v-btn
					class="mx-2"
					dark
					color="green"
					@click="doAction({Balance, Notes, Logs})"
				>
					{{Mode}}
				</v-btn>
				<v-spacer />
				<v-btn
					class="mx-2"
					dark
					@click="doClose()"
				>
					Đóng
				</v-btn>
			</v-card-actions>
		</v-card>
		<Loading :status="loadingState" />
	</v-dialog>
</template>

<script>
import Loading from '@/components/base/Loading';
export default {
	name: "deposit",
	components: {
		Loading
	},
	props: ["account", "mode", "doAction", "closeState", "loadingState"],
	data(){
		return {
			Notes: null,
			Logs: null,
			Balance: 0,
		}
	},
	computed:{
		Mode(){
			return this.mode === 'deposit' ? "Nạp Tiền":"Rút Tiền";
		}
	},
	watch:{
		closeState(){
			this.Balance = 0;
			this.Notes = null;
			this.Logs = null;
		}
	},
	methods:{
		doClose(){
			this.Balance = 0;
			this.Notes = null;
			this.Logs = null;
			this.$emit('update:closeState', false);
		}
	}
}
</script>

<style scoped>

</style>