<template>
	<div>
		<span class="headline">DANH SÁCH TÀI KHOẢN</span>
		<br />
		<v-simple-table>
			<template v-slot:default>
				<thead>
				<tr>
					<th class="text-left">
						Account
					</th>
					<th class="text-left">
						Thao tác
					</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(account,index) in Accounts"
					:key="index"
				>
					<td>{{ account.Account }}</td>
					<td>
						<v-btn
							color="red"
							icon
							x-small
							dark
							@click="onRemove(account._id)"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</td>
				</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
export default {
	name: "ListAccountCustomer",
	props:['Accounts','onRemove']
}
</script>

<style scoped>

</style>