import { Request } from '@/plugins/helper/fetch'
const req = new Request('yaccounts');
const qrEmail = (id, Email) =>{
  return req.patch('email/'+id, {Email}).then(resp=>resp.json());
}
const qrProxy = (id, ProxyData) =>{
  return req.patch('proxy/'+id, ProxyData).then(resp=>resp.json());
}
const qrAccount = (id, Account) =>{
  return req.patch('account/'+id, {Account}).then(resp=>resp.json());
}
export const qrList = (Query) => {
  return req.get('all?'+Query).then(resp=>resp.json());
}
const qrFixCookie = (id,data) => {
  return req.patch('fix/'+id, data).then(resp=>resp.json());
}
const qrFixPassword = (id,data) => {
  return req.patch('fix-password/'+id, data).then(resp=>resp.json());
}
const qrCreate = (data) => {
  return req.put('create', data).then(resp=>resp.json());
}
const qrCustomer = (Customer) =>{
  return req.post('search-customer', {Search: Customer}).then(resp=>resp.json());
}
const qrSetVIP = (Account, Customer) =>{
  return req.patch(`set-vip/${Account}`, {Customer}).then(resp=>resp.json());
}
const qrRemoveVIP = (Account) =>{
  return req.delete(`remove-vip/${Account}`).then(resp=>resp.json());
}
const qrFindCustomer = (Account) =>{
  return req.post('find-customer', {Account}).then(resp=>resp.json());
}
const qrRemovePassword = (Account) =>{
  return req.delete(`remove-password/${Account}`).then(resp=>resp.json());
}
const qrRemoveAccount = (Account) =>{
  return req.delete(`remove/${Account}`).then(resp=>resp.json());
}
const qrPauseAccount = (Account) =>{
  return req.patch(`pause/${Account}`,{do: true}).then(resp=>resp.json());
}
const qrSyncAccount = (Account) =>{
  return req.patch(`sync/${Account}`,{do: true}).then(resp=>resp.json());
}
export default {
  qrList,
  qrCreate,
  qrFixCookie,
  qrAccount,
  qrFixPassword,
  qrCustomer,
  qrSetVIP,
  qrRemoveVIP,
  qrFindCustomer,
  qrRemovePassword,
  qrRemoveAccount,
  qrPauseAccount,
  qrSyncAccount,
  qrProxy,
  qrEmail,
}