<template>
	<div>
		<v-dialog
			v-model="closeState"
			persistent
			max-width="500px"
		>
			<v-card flat>
				<v-card-title>
					DANH SÁCH SHIPMENT: {{data.Account}}
					<v-spacer />
					<v-btn
						class="mx-2"
						dark
						@click="doClose()"
					>
						Đóng
					</v-btn>
				</v-card-title>
				<v-card-text>

				</v-card-text>
				<v-card-text>
					<v-responsive>
						<v-data-table
							:options.sync="options"
							:server-items-length="data.Total"
							:loading="loadingState"
							:headers="headers"
							:items="data.Items"
							:search="search"
							loading-text="Đang tải dữ liệu..."
							:footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
							:items-per-page="20"
						>
							<template v-slot:[`item.Code`]="{ item }">
								<v-btn
									color="blue"
									dark
									text
									:to="`/shipment/${item._id}?Customer=${data.Account}`"
								>
									{{item.Code}}
								</v-btn>
							</template>
						</v-data-table>
					</v-responsive>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn
						class="mx-2"
						dark
						@click="doClose()"
					>
						Đóng
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {DoiTien} from '@/plugins/helper/string';
import Loading from '@/components/base/Loading';
import moment from "moment-timezone";
import Global from '@/global';
import { Status } from '@/plugins/helper/dstatus';
export default {
	name: "transaction",
	components: {
		Loading
	},
	data(){
		return {
			uploadID: null,
			notice: new Status(this.$swal),
			boxPhoto: false,
			file: null,
			options: {},
			search: null,
			headers: [
				{ text: 'Mã Chuyến', align: 'center', sortable: false,value: 'Code',width:"auto",class:"grey lighten-2 black--text" },
			]
		}
	},
	props: ["data", "filter", "closeState", "loadingState"],
	computed:{
	},
	watch:{
		options:{
			handler(){
				this.$emit('update:filter', {
					page: this.options.page,
					limit: this.options.itemsPerPage,
				});
			},
			deep: true
		}
	},
	methods:{
		DoiTien,
		doViewPhoto(Image){
			this.file = Image;
			this.boxPhoto = true;
		},
		doClose(){
			this.$emit('update:closeState', false);
		},
	}
}
</script>

<style scoped>

</style>