<template>
	<v-dialog
		v-model="dialog"
		max-width="500px"
		persistent
	>
		<v-card flat>
			<v-card-title>Bật tắt tính năng Shopping</v-card-title>
			<v-card-text>
				<v-combobox
					v-model="Service"
					:items="ListCategory"
					item-value="code"
					item-text="name"
					label="Lựa chọn dịch vụ"
					multiple
					class="mx-2"
				/>
			</v-card-text>
			<v-card-actions>
				<v-btn
					color="green"
					dark
					@click="doUpdate()"
				>
					Thay đổi
				</v-btn>
				<v-spacer />
				<v-btn
					dark
					@click="doClose()"
				>
					Đóng
				</v-btn>
			</v-card-actions>
		</v-card>
		<Loading :status="Loader" />
	</v-dialog>
</template>

<script>
import CategoryDB from '@/json/category.json';
import Loading from '@/components/base/Loading.vue'
export default {
	name: "ShoppingManager",
	components:{
		Loading,
	},
	props: {
		Status: {
			type: Boolean,
			default: false
		},
		Loader: {
			type: Boolean,
			default: false
		},
		Services: {
			type: Array,
			default: ()=>[]
		},
		doAction: {
			type: Function,
			default: ()=>{}
		}
	},
	computed:{
			Category: () => CategoryDB,
			ListCategory: () => Object.keys(CategoryDB).map(key => CategoryDB[key]).filter(f=>f['shopping']),
	},
	watch:{
		Services(){
			this.Service = this.Services.filter(f=>!!f).map(item=>CategoryDB[item]).filter(f=>!!f);
		},
		Status(){
			this.dialog = this.Status;
		},
		dialog(){
			if(this.dialog){
				this.Service = this.Services.filter(f=>!!f).map(item=>CategoryDB[item]).filter(f=>!!f);
			}
		}
	},
	data(){
		return {
			dialog: false,
			Service: null,
		}
	},
	methods:{
		doUpdate(){
			const service = this.Service.filter(f=>!!f === true).map(item=>item.code ? item.code: item);
			return this.doAction(service);
		},
		doClose(){
			this.dialog = false;
			this.$emit('update:status',this.dialog);
		},
	},
	mounted(){
		this.dialog = this.Status;
		this.Service = this.Services.filter(f=>!!f).map(item=>CategoryDB[item]).filter(f=>!!f);
	}
}
</script>

<style scoped>

</style>