<template>
  <div class="team">
    <v-container >
      <v-layout row wrap>
         <v-flex
            sm12
            xs12
            md12
            lg12
            v-if="Allowed"
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat><h3 class="white--text">Bộ lọc</h3></v-toolbar>
              <v-card-title>
                <v-row>
                  <v-col
                        cols="6"
                        sm="6"
                        lg="3"
                        xl="3"
                    >
                      <v-select
                        label="Trạng thái khách hàng"
                        v-model="Filter.Status"
                        :items="TrangThaiKhachHang"
                        item-value="value"
                        item-text="text"
                        outlined
												:disabled="Lock.Status"
                      />
                  </v-col>
                  <v-col
                      cols="6"
                      sm="6"
                      lg="3"
                      xl="3"
                  >
                    <v-select
                        label="Sổ địa chỉ"
                        v-model="Filter.SoDiaChi"
                        :items="SoDiaChi"
                        item-value="value"
                        item-text="text"
                        outlined
                    ></v-select>
                  </v-col>
                  <v-col
                      cols="6"
                      sm="6"
                      lg="3"
                      xl="3"
                  >
                    <v-select
                        label="Danh sách Sale"
                        v-model="Filter.Sale"
                        :items="[{_id:null,Username:'Tất cả'}].concat(ListSales)"
                        item-value="_id"
                        item-text="Username"
                        outlined
												v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
                    />
                  </v-col>
									<v-col
										cols="6"
										sm="6"
										lg="3"
										xl="3"
										v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
									>
										<v-select
											label="Trạng thái đấu giá"
											v-model="Filter.Auction"
											:items="[{value:null,text:'Tất cả'}, {value:1,text:'Đã kích hoạt'}, {value:2,text:'Chưa kích hoạt'}]"
											item-value="value"
											item-text="text"
											outlined
										/>
									</v-col>
                </v-row>
              </v-card-title>              
            </v-card>
            <br />
          </template>
         </v-flex>
         <v-flex
            sm12
            xs12
            md12
            lg12
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách khách hàng</h3>
                <v-spacer />
                <v-btn class="mx-2" color="orange" dark :loading="loading" :disabled="loading" @click="doUnlockCustomer" v-if="Allowed && Selected.length > 0 && Filter.Status == 2">Mở khóa ({{Selected.length}})</v-btn>
                <v-btn class="mx-2" color="orange" dark :loading="loading" :disabled="loading" @click="doLockCustomer" v-if="Allowed && Selected.length > 0 && Filter.Status !== 2">Khóa ({{Selected.length}})</v-btn>
                <v-btn class="mx-2" color="green" dark @click="boxAdd = true">Thêm</v-btn>
              </v-toolbar>
              <v-card-title>
                <v-row>
                  <v-col
                    cols=6
                  >
                    <p class="text-body-2">Bấm vào <strong>mã khách hàng</strong> để đổi mật khẩu cho khách hàng !!!</p>
                    <p class="text-body-2" v-if="loginInfo._perm === 'admin'">Bấm vào <strong>mã khách hàng</strong> để đổi id cho khách hàng !!!</p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Tìm kiếm"
                      single-line
                      hide-details
                      clearable
											@change="doSearch()"
                      @click:clear="doSearch()"
											v-on:keyup.enter="onSearch()"
                    />
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                v-model="Selected"
								:options.sync="Filter"
								:server-items-length="totalItems"
                :loading="loading"
                :headers="headers"
                :items="DanhSach"
                :search="search"
                loading-text="Đang tải dữ liệu..."
                :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
                :items-per-page="20"
                :show-select="Allowed"
                item-key="_id"
              >
								<template v-slot:[`item.TEBalance`]="{ item }">
									<div class="text-body2 blue--text text-center">{{ DoiTien(item.TEBalance) }}</div>
									<v-btn
										class="mx-2"
										color="green"
										x-small
										dark
										v-if="loginInfo['_perm'] === 'admin' || loginInfo['_perm'] === 'sale'"
										@click="doToggleDeposit(item)"
									>
										Nạp tiền
									</v-btn>
									<v-btn
										class="mx-2"
										color="red"
										x-small
										dark
										v-if="loginInfo['_perm'] === 'admin' || loginInfo['_perm'] === 'sale'"
										@click="doToggleWithdraw(item)"
									>
										Rút tiền
									</v-btn>
									<v-btn
										class="mx-2"
										color="blue"
										x-small
										dark
										v-if="loginInfo['_perm'] === 'admin' || loginInfo['_perm'] === 'sale'"
										@click="doLoadTransaction(item)"
									>
										Giao dịch
									</v-btn>
								</template>
                <template v-slot:[`item.Username`]="{ item }">
                  <span class="text-body2 blue--text">{{ item.Username }}</span>
                  <br>
                  <v-btn
                    color="blue"
                    small
                    dark
                    @click="toggleUpdateInfo(item)"
                  >
                    Cập nhật
                  </v-btn>
									<br />
									<v-btn
										color="blue darken-2"
										small
										dark
										@click="doLoadShipments(item)"
									>
										Danh sách chuyến hàng
									</v-btn>
                </template>
                <template v-slot:[`item.Shipment`]="{ item }">
                    <v-btn
                      color="primary"
                      @click="UpdateCuoc(item)"
                      x-small
                    >
                      Đổi Cước
                    </v-btn>
                    <p><span class="text-body blue--text">Air: {{DoiCuoc(item.Shipment)}}</span></p>
                    <p><span class="text-body blue--text">Sea: {{DoiCuoc(item.ShipmentSea)}}</span></p>
                </template>
								<template v-slot:[`item.AuctionData`]="{ item }">
									<v-btn
										color="primary"
										@click="UpdateAuction(item)"
										x-small
										v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || item.Auction === true"
									>
										Đổi Cước
									</v-btn>
                  <template v-if="item.AuctionFee">
									  <p><span class="text-body blue--text">Phí mua hộ: {{item.AuctionFee}}%</span></p>
                  </template>
                  <template v-if="item.Sale && item.JPY">
									  <p><span class="text-body blue--text">Tỉ giá: {{DoiCuoc(item.Sale.JPY+item.JPY)}} ({{item.JPY}})</span></p>
                  </template>
									<template v-if="loginInfo.Rules['Auction'] === true || loginInfo._perm === 'admin' || loginInfo._perm === 'manager'">
										<v-divider />
										<p>
											<v-btn
												class="mx-2"
												color="blue"
												dark
												@click="doLoadAccount(item)"
												small
											>
												Đấu giá ({{item['YahooAccount'].length}})
											</v-btn>
										</p>
										<p>
											<v-btn
												class="mx-2"
												color="orange"
												dark
												@click="doToggleLimit(item)"
												x-small
											>
												Giới hạn
											</v-btn>
										</p>
									</template>
								</template>
                <template v-slot:[`item.Sale`]="{ item }">
                    <template v-if="item.Sale">
                      <span class="text-body blue--text">{{item.Sale.Username}}</span>
                    </template>
                    <template v-else>
                      <span class="text-body red--text">Chưa có Sale</span>
                    </template>
                </template>
								<template v-slot:[`item.Rules`]="{ item }">

										<v-checkbox
											class="mx-2"
											v-model="item.Partner"
											label="Đối tác"
											@click="doUpdatePartnerStatus(item)"
											:disabled="loginInfo._perm !== 'admin' && loginInfo._perm !== 'manager'"
										/>
										<v-checkbox
											class="mx-2"
											v-model="item.Auction"
											label="Đấu giá"
											@click="doUpdateAuctionStatus(item)"
                      :disabled="loginInfo._perm !== 'admin' && loginInfo._perm !== 'manager'"
										/>
								</template>
                <template #[`item.PhoneJP`]="{item}">
                  <p>{{item.PhoneJP}}</p>

                  <v-btn
                    color="blue"
                    dark
                    small
                    @click="toggleSDT(item)"
                  >
                    Đổi SĐT
                  </v-btn>
                </template>
                <template #[`item.AddressVN`]="{item}">
                  <v-btn
										color="blue"
										dark
										@click="doOpenAddressBox(item)"
									>
										Sổ địa chỉ ({{item.AddressVN.length}})
									</v-btn>

                </template>
								<template #[`item.Discount`]="{item}">
									<template v-if="item.Discount === false">
										<span class="red--text">
											Chưa kích hoạt khuyến mãi
										</span>
									</template>
									<template v-else-if="item.Discount">
											<div class="text-center"><strong>Khuyến mãi: </strong> {{item.Discount.Discount}} %</div>
											<div class="text-center"><strong>Hết hạn: </strong> {{item.Discount.Expire}}</div>
									</template>
									<br />
									<div class="text-center">
										<v-btn
											class="mx-2"
											dark
											color="blue"
											small
											@click="doToggleDiscount(item)"
											v-if="loginInfo._perm === 'admin'"
										>
											Thay đổi
										</v-btn>
										<v-btn
											class="mx-2"
											dark
											color="orange"
											small
											@click="doCancelDiscount(item)"
											v-if="item.Discount !== false"
											:loading="LoaderDiscount"
											:disabled="LoaderDiscount"
										>
											Hủy khuyến mãi
										</v-btn>
									</div>
								</template>
								<template #[`item.Shopping`]="{item}">
									<template v-if="item.Shopping">
										<div class="text-center">
											<v-checkbox
												v-model="item.Shopping"
												v-for="(subitem, index) in ListCategory"
												:value="subitem.code"
												:key="index"
												:label="subitem.name"
												checked
												readonly
											/>
										</div>
									</template>
									<template v-else>
										<div class="text-center">
											<span class="red--text">
												Chưa kích hoạt Shopping
											</span>
										</div>
									</template>
									<v-btn
										color="blue"
										dark
										@click="doToggleShopping(item)"
										small
										class="mx-2"
									>
										Tùy chỉnh
									</v-btn>
								</template>
							</v-data-table>
            </v-card>
          </template>
         </v-flex>
      </v-layout>
    </v-container>
    <Add :enable.sync="boxAdd" :submitForm="Them" />
		<SM :Status.sync="boxShopping" :doAction="doUpdateShopping" :Services="ShoppingData.Service" :Loader="LoaderShopping" />
		<v-dialog
			v-model="boxDiscount"
			max-width="500px"
			persistent
			v-if="loginInfo._perm === 'admin'"
		>
			<v-card>
				<v-card-title>
					THÔNG TIN KHUYẾN MÃI
				</v-card-title>
				<v-card-text>
						<v-text-field
							v-model="dRequest.Discount"
							label="% áp dụng"
							class="mx-2"
						/>
					<v-menu
						v-model="menuDTime"
						:close-on-content-click="false"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="dRequest.Expire"
								label="Khuyến mãi đến hết ngày"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
								class="mx-2"
							/>
						</template>
						<v-date-picker
							v-model="dRequest.Expire"
							@input="menuDTime = false"
						/>
					</v-menu>
				</v-card-text>
				<v-card-actions>
					<v-btn
						class="mx-2"
						color="green"
						dark
						@click="doUpdateDiscount()"
						:disabled="dRequest.Expire === null"
					>
						<v-icon left>mdi-check</v-icon>
						Cập nhật
					</v-btn>
					<v-spacer />
					<v-btn
						class="mx-2"
						dark
						@click="boxDiscount = false"
					>
						Đóng
					</v-btn>
				</v-card-actions>
			</v-card>
			<Loading :status="LoaderDiscount" />
		</v-dialog>
		<Balance :mode="BalanceAccount.Mode" :closeState.sync="boxBalance" :account="BalanceAccount.Account" :doAction="BalanceAccount.Mode === 'deposit' ? doDeposit:doWithdraw" :loadingState="loaderBalance" />
		<Transaction :total="Transaction.Total" :filter.sync="transactionFilter" :closeState.sync="boxTransaction" :account="Transaction.Account" :data="Transaction.Data" :loadingState="loaderTransaction" :uploadState="uploadState" :doUpload="doUploadImage" :doConfirm="doConfirmTransaction" :confirmState="confirmState" />
		<Shipments :filter.sync="shipmentsFilter" :closeState.sync="boxShipments" :data="Shipments" :loadingState="loaderShipments" />
		<v-dialog
			v-model="boxAddress"
			max-width="900px"
			ransition="dialog-bottom-transition"
		>
			<v-card>
				<v-toolbar
					dark
					color="primary"
				>
					<v-btn
						icon
						dark
						@click="boxAddress = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Thông tin sổ địa chỉ</v-toolbar-title>
				</v-toolbar>
				<v-card-title>
					Sổ địa chỉ khách hàng: {{AddressData.Username}}
				</v-card-title>
				<v-card-text>
					<v-tabs
						v-model="tabAddress"
						background-color="blue"
						color="white"
						dark
					>
						<v-tab>Danh sách</v-tab>
						<v-tab>Thêm mới</v-tab>
						<v-tab v-if="AddressData.EditMode">Chỉnh sửa</v-tab>
					</v-tabs>
					<v-tabs-items
						v-model="tabAddress"
					>
						<v-tab-item>
							<v-simple-table>
								<template v-slot:default>
									<thead>
									<tr>
										<th class="text-left">
											Tên
										</th>
										<th class="text-left">
											Địa chỉ
										</th>
										<th class="text-left">
											Số điện thoại
										</th>
										<th class="text-left">
											Thao tác
										</th>
									</tr>
									</thead>
									<tbody>
									<tr
										v-for="item in AddressData.Address"
										:key="item._id"
									>
										<td>{{ item.Name }}</td>
										<td>{{ item.Address }}</td>
										<td>{{ item.Phone }}</td>
										<td>
											<v-btn
												class="mx-2"
												elevation="2"
												icon
												x-small
												color="green"
												@click="doEditAddressBox(item)"
											>
												<v-icon>mdi-pencil</v-icon>
											</v-btn>
											<v-btn
												class="mx-2"
												elevation="2"
												icon
												x-small
												color="red"
												@click="doDeleteAddressBox(item)"
											>
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</td>
									</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-tab-item>
						<v-tab-item>
							<template v-if="AddressData.Address && AddressData.Address.length <3">
								<v-row>
									<v-col
										cols="auto"
									>
										<v-text-field
											v-model="NewAddressData['Name']"
											label="Tên"
										/>
									</v-col>
									<v-col
										cols="auto"
									>
										<v-textarea
											v-model="NewAddressData['Address']"
											label="Địa chỉ"
										/>
									</v-col>
									<v-col
										cols="auto"
									>
										<v-text-field
											v-model="NewAddressData['Phone']"
											label="Phone"
										/>
									</v-col>
									<v-col
										cols="auto"
									>
										<v-btn
											color="green"
											dark
											icon
											small
											@click="doAddNewAddressBox()"
										>
											<v-icon>
												mdi-book-plus
											</v-icon>
										</v-btn>
									</v-col>
									<v-col
										cols="auto"
									>
										<v-btn
											color="red"
											dark
											icon
											small
											@click="NewAddressData = {}"
										>
											<v-icon>
												mdi-close
											</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</template>
							<template v-else>
								Số lượng địa chỉ đã đạt giới hạn
							</template>
						</v-tab-item>
						<v-tab-item>
							<v-row>
								<v-col
									cols="auto"
								>
									<v-text-field
										v-model="EditAddressData['Name']"
										label="Tên"
									/>
								</v-col>
								<v-col
									cols="auto"
								>
									<v-textarea
										v-model="EditAddressData['Address']"
										label="Địa chỉ"
									/>
								</v-col>
								<v-col
									cols="auto"
								>
									<v-text-field
										v-model="EditAddressData['Phone']"
										label="Phone"
									/>
								</v-col>
								<v-col
									cols="auto"
								>
									<v-btn
										color="green"
										dark
										icon
										small
										@click="doSaveAddressBox()"
									>
										<v-icon>
											mdi-content-save
										</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-tab-item>
					</v-tabs-items>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="green"
						dark
						@click="doUpdateAddressBox"
					>
						Lưu
					</v-btn>
					<v-spacer />
					<v-btn
						@click="boxAddress = false"
						dark
					>
						Hủy
					</v-btn>
				</v-card-actions>
			</v-card>
			<Loading :status="loading" />
		</v-dialog>
    <v-dialog
			v-model="boxDoiCuoc"
			max-width="900px"
			ransition="dialog-bottom-transition"
		>
			<v-card>
				<v-toolbar
					dark
					color="primary"
				>
					<v-btn
						icon
						dark
						@click="boxDoiCuoc = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Đổi cước: {{ Data.Name }}</v-toolbar-title>
				</v-toolbar>
				<v-card-title>
					ĐỔI CƯỚC
				</v-card-title>
				<v-card-text>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>
								<v-form v-model="valid">
									<v-row>
										<v-col
											cols="6"
										>
											<v-currency-field
												v-model="Data.Cuoc"
												label="Cước Air"
												:rules="[required('Cước Air')]"
												:hint="ThongBaoDoiCuoc"
												persistent-hint
											/>
										</v-col>
										<v-col
											cols="6"
										>
											<v-currency-field
												v-model="Data.CuocSea"
												label="Cước Sea"
												:rules="[required('Cước Sea')]"
												:hint="ThongBaoDoiCuocSea"
												persistent-hint
											/>
										</v-col>
										<v-col cols="6">
											<v-btn :disabled="!valid" color="green" dark @click="CapNhatCuoc()" v-if="loginInfo._perm !== 'manager'">
												Đổi
											</v-btn>
										</v-col>
									</v-row>
								</v-form>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="boxAuction"
			max-width="900px"
			ransition="dialog-bottom-transition"
		>
			<v-card>
				<v-toolbar
					dark
					color="primary"
				>
					<v-btn
						icon
						dark
						@click="boxAuction = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Đổi thông tin đấu giá: {{ auctionData.Name }}</v-toolbar-title>
				</v-toolbar>
				<v-card-title>
					THÔNG TIN ĐẤU GIÁ
				</v-card-title>
				<v-card-text>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>
								<v-form v-model="valid">
									<v-row>
										<v-col
											cols="6"
										>
											<v-currency-field
												v-model="auctionData.JPY"
												label="Tỉ giá"
												:rules="[required('Tỉ giá'),min(0,'JPY')]"
												:hint="ThongTinTiGia"
												persistent-hint
											/>
										</v-col>
										<v-col
											cols="6"
										>
											<v-currency-field
												v-model="auctionData.AuctionFee"
												label="Phí mua hộ"
												:rules="[required('Phí mua hộ'), min(auctionData.MinAuctionFee,'%')]"
												:hint="ThongTinPMH"
												persistent-hint
											/>
										</v-col>
										<v-col cols="6">
											<v-btn :disabled="!valid" color="green" dark @click="DoiThongTinDauGia()"
                             v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'sale'">
												Đổi
											</v-btn>
										</v-col>
									</v-row>
								</v-form>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog
      v-model="boxSDT"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>Đổi SĐT</v-card-title>
        <v-card-text>
          <v-text-field 
            v-model="DataPhone.Phone"
            label="Số điện thoại JP"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            dark
            @click="boxSDT = false"
          >
            Đóng
          </v-btn>
          <v-spacer />
          <v-btn
            color="green"
            dark
            @click="doUpdatePhone"
          >
            Cập nhật
          </v-btn>
        </v-card-actions>
      </v-card>
      <Loading :status="loaderSDT" />
    </v-dialog>
    <v-dialog
      v-model="boxInfo"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>Cập nhật thông tin: {{ UpdateData.Username }}</v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><h3>Mật khẩu mới</h3></v-list-item-title>
              <v-list-item-subtitle>
                <v-form v-model="valid">
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-text-field 
                        v-model="NewPassword"
                        label="Mật khẩu mới"
                        :rules="[minLength('Mật khẩu',6)]"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-btn :disabled="!valid" color="green" dark @click="CapNhatMatKhau">
                        Đổi
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="loginInfo._perm === 'admin'">
            <v-list-item-content>
              <v-list-item-title><h3>ID mới</h3></v-list-item-title>
              <v-list-item-subtitle>
                <v-form v-model="validID">
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-text-field 
                        v-model="NewID"
                        label="ID Mới"
                        :rules="[required('ID mới')]"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-btn :disabled="!validID" color="green" dark @click="CapNhatID">
                        Đổi
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="loginInfo._perm === 'admin'">
            <v-list-item-content>
              <v-list-item-title><h3>Sale mới</h3></v-list-item-title>
              <v-list-item-subtitle>
                <v-form v-model="validSale" v-if="loginInfo._perm === 'admin'">
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-autocomplete
                        v-model="UpdateData.Sale._id"
                        :items="ListSales"
                        item-value="_id"
                        item-text="Username"
                        label="Lựa chọn sale"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-btn :disabled="!validSale" color="green" dark @click="doUpdateSale">
                        Đổi
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-btn
            dark
            @click="boxInfo = false"
          > 
            Đóng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
		<v-dialog
			v-model="BoxYahooAccount"
			max-width="600px"
		>
			<v-card flat>
				<v-card-title>
					<span class="headline">Yahoo Account</span>
				</v-card-title>
				<v-card-text>
						<LAC :Accounts.sync="YAccounts.AccountList" :onRemove="doRemove" />
				</v-card-text>
				<v-card-text>
					<v-select
						v-model="MyAccountData"
						class="mx-2"
						:items="MyAccountListData"
						item-value="_id"
						item-text="Account"
						label="Tài khoản"
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn
						class="mx-2"
						color="green"
						:disabled="MyAccountData === null"
						dark
						small
						@click="doAddYAccount()"
					>
						Thêm vào danh sách
					</v-btn>
					<v-spacer />
					<v-btn
						dark
						@click="BoxYahooAccount = false"
					>
						Đóng
					</v-btn>
				</v-card-actions>
			</v-card>
			<loading :status="LoaderYA" />
		</v-dialog>
		<v-dialog
			v-model="BoxLimit"
			max-width="600px"
		>
			<v-card flat>
				<v-card-title>
					Giới hạn đấu giá
				</v-card-title>
				<v-card-text>
					<v-text-field
						class="mx-2"
						v-model="Limit.Bid"
						label="Giới hạn đấu giá 24hrs"
						hint="0: Tài khoản VIP - Không giới hạn"
						persistent-hint
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="green"
						dark
						@click="doUpdateLimit()"
					>
						Cập nhật
					</v-btn>
					<v-spacer />
					<v-btn
						dark
						@click="BoxLimit = false"
					>
						Đóng
					</v-btn>
				</v-card-actions>
			</v-card>
			<Loading :status="LoaderLimit" />
		</v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { Request } from '@/plugins/helper/fetch'
import { Status } from '@/plugins/helper/dstatus'
import { clone } from '@/plugins/helper/object'
import Add from '@/components/customer/add.vue'
import Dialog from '@/components/base/Dialog.vue'
import Loading from '@/components/base/Loading.vue'
import validations from '@/plugins/helper/validations'
import {serialize} from '@/plugins/helper/object'
import {debounce} from '@/plugins/helper/string'
import {DoiCuoc} from "../plugins/helper/tinhtien";
import CategoryDB from '@/json/category.json';
// import Manage from '@/components/shipment/thaotac.vue'
import ListAccountCustomer from '@/components/yaccounts/ListAccountCustomer.vue';
import {qrList} from '@/plugins/query/yaccounts';
import SM from '@/components/customer/ShoppingManager.vue';
import Balance from '@/components/customer/Balance.vue';
import Transaction from '@/components/customer/transaction.vue';
import {DoiTien} from '@/plugins/helper/string';
import Shipments from '@/components/customer/shipments.vue';
export default {
  name: 'customer',
  components: {
    Add,
    Dialog,
    Loading,
		LAC: ListAccountCustomer,
		SM,
		Balance,
		Transaction,
		Shipments,
    // Manage,
  },
  data () {
    return {
			currentTransactionData: {
				_id: null,
				Username: null
			},
			confirmState: false,
			uploadState: false,
			Shipments:{
				Account: null,
				Items: [],
				Total: 0,
				ID: null,
			},
			shipmentsFilter:{
				page: 1,
				limit: 20,
			},
			transactionFilter: {
				page: 1,
				limit: 20,
			},
			boxShipments: false,
			loaderShipments: false,
			boxTransaction: false,
			loaderTransaction: false,
			Transaction: {
				Account: null,
				Data: [],
				Total: 0,
			},
			loaderBalance: false,
			boxBalance: false,
			BalanceAccount: {
				ID: null,
				Balance: 0,
				Account: null,
				Mode: 'deposit',
			},
			LoaderShopping: false,
			ShoppingData: {
				ID: null,
				Service: [],
			},
			boxShopping: false,
			LoaderDiscount: false,
			dRequest: {
				ID: null,
				Discount: 0,
				Expire: null,
			},
			menuDTime: false,
			boxDiscount: false,
			LoaderLimit: false,
			Limit: {
				Bid: 0,
				ID: null
			},
			BoxLimit: false,
			MyAccountData: null,
			MyAccountList:[],
			YAccounts:{
				AccountList: [],
				ID: null,
			},
			BoxYahooAccount: false,
			LoaderYA: false,
			options:{},
			totalItems: 0,
      Selected:[],
      closed: false,
      valid:false,
      validID:false,
      validSale: false,
			Lock:{
				Status: false,
			},
      Data: {
        Cuoc:0,
        Min: 0,
        ID: undefined,
      },
      DataPhone: {
        Phone: '',
        ID: '',
      },
      UpdateData: {
        Sale:{
          _id:undefined,
        }
      },
      UpdateID: undefined,      
      notice: new Status(this.$swal),
      loading: false,
      search: "",
      NewPassword: '',
      NewSale: undefined,
      NewID: '',
      req: new Request('customer'),
      reqStaff: new Request("staff"),
      TrangThaiKhachHang:[{value:0,text:'Đã có Sale'},{value:1,text:'Chưa có Sale'},{value:2,text:'Đã khóa'}],
      SoDiaChi:[{value:null,text:'Tất cả'},{value:1,text:'Đã có địa chỉ'},{value:2,text:'Chưa có địa chỉ'}],
      Sales: [],
      items: [],
      loaderSDT:false,
      boxAdd: false,
      boxDoiCuoc: false,
      boxSDT: false,
      boxInfo: false,
      boxDoiSale: false,
      boxAddressVN: false,
			boxAddress: false,
			tabAddress: 0,
      Filter:{
				Search: null,
        Status:0,
        Sale:null,
        SoDiaChi:null,
				Auction: null,
      },
			AddressData:{

			},
			NewAddressData:{},
			EditAddressData: {},
			timeOut: null,
			boxAuction: false,
			auctionData: {
				JPY:0,
				AuctionFee:0,
			},
    }
  },
  computed: {
    ...mapGetters(['authStatus','isAuthenticated','loginInfo']),
		ListCategory: () => Object.keys(CategoryDB).map(key => CategoryDB[key]).filter(f=>f['shopping']),
		headers(){
			let headers = [
				{ text: 'Mã KH', align: 'center', sortable: false,value: 'Username',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Số dư', align: 'center', sortable: false,value: 'TEBalance',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Đối tác', align: 'center', sortable: false,value: 'Rules',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Tên JP', align: 'center', sortable: false,value: 'NameJP',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Đấu giá', align: 'center', sortable: true,value: 'AuctionData',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Khuyến mãi', align: 'center', sortable: false,value: 'Discount',width: "auto",class:"grey lighten-2 black--text" },
				{ text: 'Cước', align: 'center', sortable: true,value: 'Shipment',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Sale', align: 'center', sortable: true,value: 'Sale',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'SĐT JP', align: 'center', sortable: false,value: 'PhoneJP',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Địa chỉ VN', align: 'center', sortable: false,value: 'AddressVN',width:"auto",class:"grey lighten-2 black--text" },
					// { text: 'Thao tác', align: 'center', sortable: false,value: '_id',width: "5%",class:"grey lighten-2 black--text" },
				]
			if(this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager'){
				headers.push({ text: 'Shopping', align: 'center', sortable: true,value: 'Shopping',width:"auto",class:"grey lighten-2 black--text" },)
			}
			return headers;
		},
		CurrentAccountList()
		{
			return this.YAccounts.AccountList.map(item=>item._id);
		},
		MyAccountListData(){
			return this.MyAccountList.filter(item=>!this.CurrentAccountList.includes(item._id));
		},
		ThongTinPMH(){
			const {auctionData} = this;
			let {MinAuctionFee} = auctionData;
			return `Không được bé hơn ${MinAuctionFee}%`;
		},
		ThongTinTiGia(){
			const {auctionData} = this;
			let {JPY,SaleJPY} = auctionData;
			JPY = parseInt(JPY);
			JPY = isNaN(JPY)?0:JPY;
			if(JPY < 0) return `Tỉ giá chênh lệch không được thấp hơn 0`;
			return `Tỉ giá đối tác: ${DoiCuoc(1,false)} = ${DoiCuoc(SaleJPY+JPY)}`
		},
    Allowed(){
      if(this.loginInfo._perm === 'manager' || this.loginInfo._perm === 'admin') return true;
      return false;
    },
    ListSales(){
      return this.Sales.map(
          (items, index) => {
            if(items.Permision === 'sale') return {
            ...items,
            index: index + 1,
            }
          }
        );
    },
    ThongBaoDoiCuoc(){
      return `Không được bé hơn ${this.DoiCuoc(this.Data.Min)}`
    },
    ThongBaoDoiCuocSea(){
      return `Không được bé hơn ${this.DoiCuoc(this.Data.MinSea)}`
    },
    DanhSach: function(){
        return this.items.map(
          (item, index) => ({
              ...item,
              index: index + 1,
          }));
      },
  },
	watch: {
		Filter: {
			handler () {
				const { Sale } = this.Filter;
				if(Sale !== null){
					this.Filter.Status = 0;
					this.Lock.Status = true;
				}else{
					this.Lock.Status = false;
				}
				this.LayDuLieu()
			},
			deep: true,
		},
	},
  methods: {
    ...validations,
		debounce,
		DoiTien,
		DoiCuoc,
		async doConfirmTransaction(id){
			const confirmed = await this.notice.confirm('Bạn có chắc chắn muốn xác nhận giao dịch này?');
			if(!confirmed) return;
			this.confirmState = true;
			this.req.patch(`transaction/confirm/${id}`, {confirm: true}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					this.notice.success('Đã xác nhận giao dịch');
					this.doLoadTransaction(this.currentTransactionData);
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi xác nhận giao dịch !!!';
					this.notice.error(msg);
				}
			})
			.catch(e=>{
				console.error(e);
				this.notice.error(e.message);
			})
			.finally(()=>{
				this.confirmState = false;
			});
		},
		doUploadImage(id, data){
			this.uploadState = true;
			this.req.UploadData(`transaction/invoice/${id}`, data).then(async resp=>{
				const json = resp.data;
				if(json.success){
					this.notice.success('Đã cập nhật hình ảnh');
					this.doLoadTransaction(this.currentTransactionData);
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi tải lên hình ảnh giao dịch !!!';
					this.notice.error(msg);
				}
			})
			.catch(e=>{
				console.error(e);
				this.notice.error(e.message);
			})
			.finally(()=>{
				this.uploadState = false;
			});
		},
		doLoadShipments(item){
			const ID = item._id;
			this.Shipments.Account = item.Username;
			this.Shipments.ID = ID;
			this.currentTransactionData = {
				_id: item._id,
				Username: item.Username,
			};
			this.boxShipments = true;
			this.loaderShipments = true;
			const filter = serialize(this.shipmentsFilter);
			this.req.get(`shipments/${ID}?${filter}`).then(async resp=>{
				const json = await resp.json();
				if(json.data){
					this.Shipments.Items = json.data;
					this.Shipments.Total = json.total;
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi tải thông tin chuyến !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					console.error(e);
					this.notice.error(e.message);
				})
				.finally(()=>{
					this.loaderShipments = false;
				});
		},
		doLoadTransaction(item){
			const ID = item._id;
			this.Transaction.Account = item.Username;
			this.currentTransactionData = {
				_id: item._id,
				Username: item.Username,
			};
			this.boxTransaction = true;
			this.loaderTransaction = true;
			const filter = serialize(this.transactionFilter);
			this.req.get(`transaction/${ID}?${filter}`).then(async resp=>{
				const json = await resp.json();
				if(json.data){
					this.Transaction.Data = json.data;
					this.Transaction.Total = json.total;
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi tải giao dịch !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					console.error(e);
					this.notice.error(e.message);
				})
				.finally(()=>{
					this.loaderTransaction = false;
				});
		},
		doToggleWithdraw(item){
			this.BalanceAccount = {
				ID: item._id,
				Account: item.Username,
				Balance: 0,
				Mode: 'withdraw',
			}
			this.boxBalance = true;
		},
		doToggleDeposit(item){
			this.BalanceAccount = {
				ID: item._id,
				Account: item.Username,
				Balance: 0,
				Mode: 'deposit',
			}
			this.boxBalance = true;
		},
		doWithdraw({Balance, Notes, Logs}){
			const {ID} = this.BalanceAccount;
			if(ID === null){
				this.notice.error('Tài khoản không hợp lệ !!!');
				return;
			}
			this.loaderBalance = true;
			this.req.patch(`balance/withdraw/${ID}`,{Balance, Notes, Logs}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					const msg = 'Đã cập nhật thành công !!!';
					this.notice.success(msg);
					this.boxBalance = false;
					this.LayDuLieu();
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					console.error(e);
					this.notice.error(e.message);
				})
				.finally(()=>{
					this.loaderBalance = false;
				});
		},
		doDeposit({Balance, Notes, Logs}){
			const {ID} = this.BalanceAccount;
			if(ID === null){
				this.notice.error('Tài khoản không hợp lệ !!!');
				return;
			}
			this.loaderBalance = true;
			this.req.patch(`balance/deposit/${ID}`,{Balance, Notes, Logs}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					const msg = 'Đã cập nhật thành công !!!';
					this.notice.success(msg);
					this.boxBalance = false;
					this.LayDuLieu();
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					console.error(e);
					this.notice.error(e.message);
				})
				.finally(()=>{
					this.loaderBalance = false;
				});
		},
		doToggleShopping(item){
			this.ShoppingData = {
				ID: item._id,
				Service: item.Shopping,
			}
			this.boxShopping = true;
		},
		doUpdateShopping(Shopping){
			const {ID} = this.ShoppingData;
			if(ID === null){
				this.notice.error('Tài khoản không hợp lệ !!!');
				return;
			}
			Shopping = (Array.isArray(Shopping) && Shopping.length > 0) ? Shopping:false;
			this.LoaderShopping = true;
			this.req.patch(`shopping/${ID}`,{Shopping}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					const msg = 'Đã cập nhật thành công !!!';
					this.notice.success(msg);
					this.boxShopping = false;
					this.LayDuLieu();
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					console.error(e);
					this.notice.error(e.message);
				})
				.finally(()=>{
					this.LoaderShopping = false;
				});
			//this.boxShopping = false;
		},
		doCancelDiscount(item){
			const {_id} = item;
			if(_id === null){
				this.notice.error('Tài khoản không hợp lệ !!!');
				return;
			}
			this.LoaderDiscount = true;
			this.req.patch(`cancel_discount/${_id}`,{Cancel: true}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					const msg = 'Đã cập nhật thành công !!!';
					this.notice.success(msg);
					this.boxDiscount = false;
					this.LayDuLieu();
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					console.error(e);
					this.notice.error(e.message);
				})
				.finally(()=>{
					this.LoaderDiscount = false;
				});
		},
		doUpdateDiscount(){
			const {ID, Expire} = this.dRequest;
			if(ID === null){
				this.notice.error('Tài khoản không hợp lệ !!!');
				return;
			}
			if(Expire === null){
				this.notice.error('Thời gian khuyến mãi không hợp lệ !!!');
				return;
			}
			const Discount = {
				Discount: this.dRequest.Discount,
				Expire: this.dRequest.Expire,
			}
			this.LoaderDiscount = true;
			this.req.patch(`discount/${ID}`,{Discount}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					const msg = 'Đã cập nhật thành công !!!';
					this.notice.success(msg);
					this.boxDiscount = false;
					this.LayDuLieu();
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
					this.notice.error(msg);
				}
			})
			.catch(e=>{
				console.error(e);
				this.notice.error(e.message);
			})
			.finally(()=>{
				this.LoaderDiscount = false;
			});
		},
		doToggleDiscount(item){
			this.dRequest = {
				ID: item._id,
				Discount: item.Discount ? item.Discount.Discount :0,
				Expire: item.Discount ? item.Discount.Expire : null,
			}
			this.boxDiscount = true;
		},
		doUpdateLimit(){
			const {ID, Bid} = this.Limit;
			if(ID === null){
				this.notice.error('Tài khoản không hợp lệ !!!');
				return;
			}
			this.LoaderLimit = true;
			this.req.patch(`bid_limit/${ID}`,{Bid}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					const msg = 'Đã cập nhật thành công !!!';
					this.notice.success(msg);
					this.BoxLimit = false;
					this.LayDuLieu();
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					console.error(e);
					this.notice.error(e.message);
				})
				.finally(()=>{
					this.LoaderLimit = false;
				});
		},
		doToggleLimit(item){
			this.Limit = {
				ID: item._id,
				Bid: item['LimitBid']
			}
			this.BoxLimit = true;
		},
		doAddYAccount(){
			const {MyAccountData, YAccounts} = this;
			if(MyAccountData && YAccounts.ID){
				this.req.patch(`yaccount/${YAccounts.ID}`,{Add:MyAccountData}).then(async resp=>{
					const json = await resp.json();
					if(json.success){
						const msg = 'Đã cập nhật thành công !!!';
						this.notice.success(msg);
						this.MyAccountData = null;
						this.YAccounts.AccountList = json.data;
						this.LayDuLieu();
					}
					if(json.error){
						const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
						this.notice.error(msg);
					}
				})
					.catch(e=>{
						console.error(e);
						this.notice.error(e.message);
					})
					.finally(()=>{
						this.LoaderYA = false;
					});
			}else{
				this.notice.error('Thông tin tài khoản không hợp lệ !!!');
			}
		},
		doRemove(id){
			this.LoaderYA = true;
			const {YAccounts} = this;
			if(!YAccounts.ID){
				this.notice.error('Thông tin tài khoản không hợp lệ !!!');
				return;
			}
			this.req.patch(`yaccount/${YAccounts.ID}`,{Remove:id}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					const msg = 'Đã cập nhật thành công !!!';
					this.notice.success(msg);
					this.YAccounts.AccountList = json.data;
					this.LayDuLieu();
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
					this.notice.error(msg);
				}
			})
			.catch(e=>{
				console.error(e);
				this.notice.error(e.message);
			})
			.finally(()=>{
				this.LoaderYA = false;
			});
		},
		doLoadAccount(item){
			this.YAccounts = {
				AccountList: item['YahooAccount'],
				ID: item._id
			}
			this.BoxYahooAccount = true;
		},
		UpdateAuction(item){
			this.auctionData = {
				JPY:item.JPY,
				AuctionFee:item.AuctionFee > item.Sale.AuctionFee ? item.AuctionFee : item.Sale.AuctionFee,
				SaleJPY:item.Sale.JPY,
				MinAuctionFee:item.Sale.AuctionFee,
				ID: item._id,
				Name: item.Username,
			}
			this.boxAuction = true;
		},
		onSearch(){
			this.Filter.Search = this.search;
		},
		doSearch(){
			clearTimeout(this.timeOut);
			const app = this;
			this.timeOut = setTimeout(() => {
				app.Filter.Search = app.search;
			}, 1000);
		},
		doUpdateAuctionStatus(item){
			if(this.loginInfo._perm !== 'admin' && this.loginInfo._perm !== 'manager') return;
			const {_id, Auction} = item;
			this.loading = true;
			this.req.patch(`auction/${_id}`,{Auction}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					const msg = 'Đã cập nhật thành công !!!';
					this.notice.success(msg);
					this.LayDuLieu();
				}
				if(json.error){
					const index = this.items.findIndex(f=>f._id === _id);
					this.items[index].Partner = !this.items[index].Partner;
					const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					this.notice.error(e);
				}).finally(()=>{
				this.loading = true;
			});
		},
		doUpdatePartnerStatus(item){
			if(this.loginInfo._perm !== 'admin' && this.loginInfo._perm !== 'manager') return;
			const {_id, Partner} = item;
			this.loading = true;
			this.req.patch(`partner/${_id}`,{Partner}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					const msg = 'Đã cập nhật thành công !!!';
					this.notice.success(msg);
					this.LayDuLieu();
				}
				if(json.error){
					const index = this.items.findIndex(f=>f._id === _id);
					this.items[index].Partner = !this.items[index].Partner;
					const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					this.notice.error(e);
				}).finally(()=>{
				this.loading = true;
			});
		},
		doUpdateAddressBox(){
			const {_id,Address} = this.AddressData;
			this.loading = true;
			this.req.patch(`address/${_id}`,{Address}).then(async resp=>{
				const json = await resp.json();
				if(json.success){
					const msg = json.data || 'Đã cập nhật thành công !!!';
					this.notice.success(msg);
					this.LayDuLieu();
					this.boxAddress = false;
					this.EditAddressData = {};
					this.NewAddressData = {};
					this.AddressData = {};
				}
				if(json.error){
					const msg = json.error || 'Có lỗi khi cập nhật tài khoản !!!';
					this.notice.error(msg);
				}
			})
				.catch(e=>{
					this.notice.error(e);
				}).finally(()=>{
				this.loading = true;
			});
		},
		doSaveAddressBox(){
			const {_id, Name, Phone, Address} = this.EditAddressData;
			const index = this.AddressData.Address.findIndex(v=>v._id === _id);
			if(index > -1){
				const oldData = this.AddressData.Address[index];
				const newData = {
					...oldData,
					Name,
					Phone,
					Address
				}
				this.AddressData.Address[index] = newData;
				this.tabAddress = 0;
				this.EditAddressData = {};
				this.AddressData.EditMode = false;
			}else{
				this.notice.error('Địa chỉ không hợp lệ !!!');
			}
		},
		doEditAddressBox(item){
			this.EditAddressData = item;
			this.AddressData.EditMode = true;
			this.tabAddress = 2;
		},
		doDeleteAddressBox(item){
			const index = this.AddressData.Address.findIndex(v=>v.Name === item.Name && v.Phone === item.Phone && v.Address === item.Address);
			if(index > -1){
				this.AddressData.Address.splice(index,1);
			}else{
				this.notice.error('Địa chỉ không hợp lệ !!!');
			}
		},
		doAddNewAddressBox(){
			const {Name, Phone, Address} = this.NewAddressData;
			const index = this.AddressData.Address.findIndex(v=>v.Name === Name && v.Phone === Phone && v.Address === Address);
			if(index > -1) {
				this.notice.error('Địa chỉ đã tồn tại !!!');
				return;
			}
			this.AddressData.Address.push(this.NewAddressData);
			this.NewAddressData = {};
			this.tabAddress = 0;
		},
		doOpenAddressBox(item){
			const itemData = JSON.parse(JSON.stringify(item));
			this.AddressData = Object.assign({},{
				Username: itemData.Username,
				_id: itemData._id,
				Address: (itemData.AddressVN) ? itemData.AddressVN:[],
				EditMode: false,
			});
			this.NewAddressData = {};
			this.EditAddressData = {};
			this.boxAddress = true;
		},
    toggleUpdateInfo(item){
      this.UpdateData = item;
      if(!this.UpdateData.Sale){
        this.UpdateData.Sale = {
          _id:undefined,
        }
      }
      this.boxInfo = true;
    },
    async doUnlockCustomer(){
      if(this.Selected.length <= 0) return;
      const Accounts = this.Selected.map(item=>item._id);
      const xac_nhan = await this.notice.confirm('Có xác nhận mở khóa tài khoản ?');
      if(xac_nhan){
        this.loading = true;
        this.req.unlock({
          Accounts
        }).then(async resp=>{
          const json = await resp.json();
          if(json.success){
            const msg = json.data || 'Đã mở khóa thành công !!!';
            this.notice.success(msg);
            this.LayDuLieu();
          }
          if(json.error){
            const msg = json.error || 'Có lỗi khi mở khóa tài khoản !!!';
            this.notice.error(msg);
          }
        })
        .catch(e=>{
          this.notice.error(e);
        }).finally(()=>{
          this.loading = true;
        })

      }
    },
    async doLockCustomer(){
      if(this.Selected.length <= 0) return;
      const Accounts = this.Selected.map(item=>item._id);
      const xac_nhan = await this.notice.confirm('Có xác nhận khóa tài khoản ?');
      if(xac_nhan){
        this.loading = true;
        this.req.lock({
          Accounts
        }).then(async resp=>{
          const json = await resp.json();
          if(json.success){
            const msg = json.data || 'Đã khóa thành công !!!';
            this.notice.success(msg);
            this.LayDuLieu();
          }
          if(json.error){
            const msg = json.error || 'Có lỗi khi khóa tài khoản !!!';
            this.notice.error(msg);
          }
        })
        .catch(e=>{
          this.notice.error(e);
        }).finally(()=>{
          this.loading = true;
        })

      }
    },
    doUpdatePhone(){
      this.loaderSDT = true;
      const {ID,Phone} = this.DataPhone;
      this.req.update(ID,{PhoneJP:Phone}).then(async resp=>{
        const json = await resp.json()
        if(json.success){
          this.notice.success("Đã đổi sđt khách hàng thành công !!!")
          this.DataPhone = {
            Phone: '',
            ID: '',
          }
          this.boxSDT = false;
          this.LayDuLieu();
        }else{
          const e = json.error || "Không đổi được sđt khách hàng !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e);
      }).finally(()=>{
        this.loaderSDT = false;
      })
    },
    toggleSDT(item){
      this.DataPhone.Phone = item.PhoneJP; this.DataPhone.ID = item._id;
      this.boxSDT = true;
    },
    doUpdateSale(){
      const id = this.UpdateData._id;
      this.loading = true;
      const Sale = this.UpdateData.Sale._id;
      this.req.update(id,{Sale}).then(async resp=>{
        const json = await resp.json()
        if(json.success){
          this.notice.success("Đã đổi Sale khách hàng thành công !!!")
          this.boxInfo = true;
          this.LayDuLieu();
        }else{
          const e = json.error || "Không đổi được Sale khách hàng !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e);
      }).finally(()=>{
        this.loading = false;
      })
    },
    UpdateSale(data){
      this.NewSale = data;
    },
    UpdateCuoc(item){
      this.Data = {
        Name: item.Username,
        Cuoc: item.Shipment,
        CuocSea: item.ShipmentSea,
        Min: item.Sale.Shipment,
        MinSea: item.Sale.ShipmentSea,
        ID: item._id
      }
      this.boxDoiCuoc = true
    },
    CapNhatID(){
      const id = this.UpdateData._id;
      this.req.update(id,{Username:this.NewID}).then(async resp=>{
        const json = await resp.json()
        if(json.success){
          this.notice.success("Đã đổi id khách hàng thành công !!!")
          this.closed = true;
          this.NewID = '';
          this.LayDuLieu();
        }else{
          const e = json.error || "Không đổi được id khách hàng !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
    CapNhatMatKhau(){
      const id = this.UpdateData._id;
      this.req.update(id,{Password:this.NewPassword}).then(async resp=>{
        const json = await resp.json()
        if(json.success){
          this.notice.success("Đã đổi mật khẩu khách hàng thành công !!!")
          this.closed = true
          this.NewPassword = ''
        }else{
          const e = json.error || "Không đổi mật khẩu khách hàng !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
		DoiThongTinDauGia(){
			if(!this.auctionData.ID)
			{
				return
			}
			if(this.auctionData.AuctionFee < this.auctionData.MinAuctionFee){
				this.notice.error(this.ThongTinPMH)
				return
			}
			this.req.update(this.auctionData.ID,{JPY:this.auctionData.JPY,AuctionFee:this.auctionData.AuctionFee}).then(async resp=>{
				const json = await resp.json()
				if(json.success){
					this.notice.success("Đã đổi thông tin đấu giá thành công !!!")
					this.closed = true;
					this.boxAuction = false;
					this.LayDuLieu()
				}else{
					const e = json.error || "Không đổi được thông tin đấu giá !!!"
					this.notice.error(e)
				}
			})
			.catch(e=>{
				this.notice.error(e)
			})
		},
    CapNhatCuoc(){
      if(!this.Data.ID)
      {
        return
      }
      if(this.Data.Cuoc < this.Data.Min){
        this.notice.error(this.ThongBaoDoiCuoc)
        return
      }
      if(this.Data.CuocSea < this.Data.MinSea){
        this.notice.error(this.ThongBaoDoiCuocSea)
        return
      }
      this.req.update(this.Data.ID,{Shipment:this.Data.Cuoc,ShipmentSea:this.Data.CuocSea}).then(async resp=>{
        const json = await resp.json()
        if(json.success){
          this.notice.success("Đã đổi cước thành công !!!")
          this.closed = true
          this.LayDuLieu()
        }else{
          const e = json.error || "Không đổi được cước !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
    LayDuLieu() {
      this.loading = true
      this.req.fetch('?'+serialize(this.Filter)).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.items = json.data;
					this.totalItems = json.total;
        }else{
          const msg = json.error || "Lỗi kết nối máy chủ !!!"
          this.notice.error(msg)
        }
      })
      .catch(err=>{
        this.notice.error(err)
      })
      .finally(()=>{
        this.loading = false
      })
    },
    TimKiem(){
      
    },
    Them(data){
      this.req.create(data).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.boxAdd = false
          this.notice.success("Đã thêm mới dữ liệu thành công !!!")
          this.LayDuLieu()
        }else{
          const msg = json.error || "Không thể thêm mới khách hàng !!!"
          this.notice.error(msg)
        }
      })
    },
    Xoa(item){
      this.req.delete(item._id).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.notice.success("Đã xóa dữ liệu !!!");
          this.LayDuLieu()
        }else{
          const msg = json.error || "Không thể xóa dữ liệu !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
    },
    DuLieuDiaChi(item){
      const newItem = clone(item)
      return newItem
    },
    CapNhatDiaChi(ID,Address){
        this.req.update(ID,{Vietnam:Address}).then(async resp=>{
          const json = await resp.json()
          if(json.success === true){
            this.notice.success("Đã cập nhật dữ liệu !!!");
            this.LayDuLieu()
          }else{
            const msg = json.error || "Không thể xóa dữ liệu !!!"
            this.notice.error(msg);
          }
        })
        .catch(err=>{
          this.notice.error(err);
        })
    },
    getSales(){
			if(this.loginInfo._perm !== 'admin' && this.loginInfo._perm !== 'manager') return;
      this.loading = true
      this.reqStaff.fetch("sales").then(async resp=>{
        const json = await resp.json()
        if(json.data) this.Sales = json.data
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loading = false
      })
    },
    getQueryUsername(){
      if(this.$route.query.username){
        this.search = this.$route.query.username;
        return this.doSearch();
      }
    }
  },
  mounted(){
    //this.LayDuLieu();
    this.getQueryUsername();
    this.getSales();
		qrList('page=1&itemsPerPage=500&Status=dang_hoat_dong').then(json=>{
			this.MyAccountList = json.data;
		});
	},
}
</script>

